import * as React from 'react';
import {
  // Button,
  Container,
  // Divider,
  Grid,
  TextInputField,
  Typography,
} from 'src/app/components';
import { useStyles } from 'src/app/context';
import { useTheme } from '@material-ui/core/styles';

const Testset = ({ onDarkBackground }: { onDarkBackground?: boolean }) => {
  const styles = useStyles();
  return (
    <Grid container spacing={2} style={{ padding: '64px' }}>
      <Grid item xs={12}>
        <Typography
          className={onDarkBackground ? styles.onDarkBackground : ''}
          align={'center'}
          variant={'h4'}
        >
          Test text
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={onDarkBackground ? styles.onDarkBackgroundPrimary : ''}
          color={'primary'}
          align={'center'}
          variant={'h4'}
        >
          Test text primary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={onDarkBackground ? styles.onDarkBackgroundSecondary : ''}
          color={'secondary'}
          align={'center'}
          variant={'h4'}
        >
          Test text secondary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          className={onDarkBackground ? styles.onDarkBackground : ''}
          label={'Default TextInputField'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          className={onDarkBackground ? styles.onDarkBackground : ''}
          variant={'filled'}
          label={'Filled TextInputField'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          className={onDarkBackground ? styles.onDarkBackground : ''}
          variant={'outlined'}
          label={'Outlined TextInputField'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          color={'primary'}
          className={'foo'}
          // className={onDarkBackground ? styles.onDarkBackground : ''}
          label={'Primary TextInputField'}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default () => {
  // const theme = useTheme();
  if (
    typeof window === 'undefined' ||
    (window.location.hostname !== 'localhost' &&
      window.location.hostname !== 'travlr-dev.netlify.app') // TODO: .env
  ) {
    return null;
  }
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography align={'center'} variant={'h4'}>
            Components tests
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography>
            <pre>{JSON.stringify(theme, null, '  ')}</pre>
          </Typography>
        </Grid> */}
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <Typography align={'center'} variant={'h4'}>
                Components light
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Testset />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <Typography align={'center'} variant={'h4'}>
                Components dark
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: '#222222aa' }}>
              <Testset onDarkBackground />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
